.footer{
    margin-top: 60px;
    height: 50px;
    background-color: #207D47;
    color: aliceblue;
    display: flex;
    justify-content: center;
}

.link{
    font-style: italic;
    color: #ffff;
}