body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-size: 32px;
    text-align: justify;
    margin-top: 50px;
    margin: 20px 50px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 50px;
    text-align: justify;
  }
  
  @media screen and (max-width: 768px) {
    p {
      font-size: 16px;
      margin: 5px 20px;
    }
    h1 {
        font-size: 20px;
        text-align: justify;
        margin-top: 50px;
        margin: 20px 20px;
      }
  }

  